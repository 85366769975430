import React from 'react';
import './App.scss';
import { BrowserRouter as Router, Route } from "react-router-dom";
import Movie from '../src/components/movie/movie';
import Home from '../src/components/home/home';

function App() {
  return (
    <div className="App">
      <Router>
        <Route exact path="/" component={Home} />
        <Route exact path="/movies/:id/:searchDate/:zip" component={Movie} />
      </Router>
    </div>
  );
}

export default App;