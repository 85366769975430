import React, {useState, useEffect} from 'react';
import styles from './card.module.scss';
import classNames from 'classnames';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { IoIosCloseCircleOutline } from 'react-icons/io';

const Card = (props) => {
    const [timeCards, setTimeCards] = useState([]);
    const times = ["6:30PM", "7:45PM", "8:45PM", "9:30PM", "10:30PM"]   
    let card = document.getElementById(`Card${props.index}`);
    const baseImageURL = "https://image.tmdb.org/t/p/w1280";

    useEffect(() => {
        setTimeCards(createTimeCards());
    }, [])
    
    const createTimeCards = () => {
        let timeDivs = [];
        times.map((time, index) => {
            return timeDivs.push(
                <div className={styles.timeSlot} key={index}>{time}</div>
            )
        })
        console.log(timeDivs)
        return timeDivs;
    }


    const toggleFlip = (e) => {
        e.preventDefault();
        e.stopPropagation();
        card.classList.toggle(styles.isFlipped);
    }

    return (
    <div className={styles.Scene}>
        <div className={classNames(styles.Card, 'Card')} id={"Card"+props.index}>
            <Link to={`movies/${props.id}/${props.searchDate}/${props.zip}`}>
                <div className={classNames(styles.Card_face, styles.Card_face__front)}>
                    <img 
                        className={styles.Card_face__front___img } 
                        src={baseImageURL+props.img} 
                        alt={props.title}
                    />
                    <div className={styles.Card_flip} onClick={e => toggleFlip(e)}>
                        <FaRegCalendarAlt/>
                    </div>
                    <div className={styles.Card_title}>{props.title}</div>
                    <span className={styles.Card_rating}>{`${props.rating} / 10 | ${props.mpaa}`}</span>
                    <div className={styles.Card_overview}>{props.overview}</div>

                </div>
            </Link>
            <div className={classNames(styles.Card_face, styles.Card_face__back)}>
                <IoIosCloseCircleOutline className={styles.Card_revert} onClick={e => toggleFlip(e)}/>
                <div className={styles.times}>
                    <div className={styles.theater_block}>
                        <div className={styles.times_theater}>AMC Freehold Metroplex:</div>
                        <div className={styles.times_theaterDistance}>2 mi</div>
                        <div className={styles.times_allTimes}>{timeCards.slice(0, timeCards.length/2)}</div>
                    </div>

                    <div className={styles.theater_block}>
                        <div className={styles.times_theater}>New Brunswick Cinemas:</div>
                        <div className={styles.times_theaterDistance}>5 mi</div>
                        <div className={styles.times_allTimes}>{timeCards.slice(timeCards.length/2, timeCards.length)}</div>
                    </div>

                    <div className={styles.theater_block}>
                        <div className={styles.times_theater}>AMC Monmouth Mall:</div>
                        <div className={styles.times_theaterDistance}>10 mi</div>
                        <div className={styles.times_allTimes}>{timeCards}</div>
                    </div>
                </div>
            </div>

        </div>

    </div>
    )
}

export default Card;

Card.propTypes = {
    title: PropTypes.string
}