import React, {useState, useEffect} from 'react';
import styles from './home.module.scss';
import Card from '../card/card';
import TopBar from '../topBar/topBar';
import Featured from '../featured/featured';
import DatePicker from 'react-date-picker';
import EditableLabel from 'react-inline-editing';
import {
    movies_index
} from '../../config/algolia';


const Home = () => {

    const [movies, setMovies] = useState();
    const [date, setDate] = useState(new Date());
    const [query, setQuery] = useState("");
    const [cards, setCards] = useState();
    const [zip, setZip] = useState("07751");
    const [displayType, setDisplayType] = useState("list");
    const [selectedTag, setSelectedTag] = useState("home");

    useEffect(() => {
        loadMovies(query);
    }, [])

    useEffect(() => {
        if (displayType === "list") loadMovies(query);
    }, [displayType, query])


    useEffect(() => {
        console.log(cards)
    }, [cards])

    const buildCards = (movies) => {
        if (!movies) return;
        let allCards = [];
        movies.map((movie, index) => {
            return allCards.push(
                <Card
                  title={movie.title}
                  img={movie.backdrop_path}
                  id={movie.objectID}
                  date={movie.date}
                  provider={movie.provider}
                  rating={movie.vote_average}
                  mpaa={movie.mpaa}
                  overview={movie.overview}
                  key={index}
                  searchDate={formatDate(date)}
                  zip={zip}
                  index={index}
                />
            )
        })
        return allCards;
    } 

    useEffect(() => {
        console.log(selectedTag)
        if (!movies || displayType !== "list") return;
        let newCards = [];
        if (selectedTag !== "home"){
            document.getElementById("home").classList.add(styles.inactive);
            if (selectedTag === "all") {
                setCards(buildCards(movies))
                return
            }
            for (let movie in movies) {
                if (movies[movie].genre_ids && movies[movie].genre_ids.includes(selectedTag)) {
                    newCards.push(movies[movie])
                }
            }
            setCards(buildCards(newCards))
        } else {
            document.getElementById("home").classList.remove(styles.inactive);
            setCards(buildCards(movies))
        }    
    }, [selectedTag, movies])  


    useEffect(() => {
        setCards(buildCards(movies))
    }, [movies])  

    const handleFocus = (text) => {
        if (text === "") {
            setZip("90210");
        }
    }

    const handleFocusOut = (text) => {
        console.log(text === "");
        if (text === "") {
            setZip("90210");
        } else {
            setZip(text);
        }
    }


    const loadMovies = (query) => {
        movies_index.search(`${query}`)
        .then((data) => {
            console.log(data.hits);
			setMovies(data.hits);
        })
    }

    const changeDate = (newDate) => {
        setDate(newDate);
    }

    const formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [month, day, year].join('-');
    }

    return (
        <div className={styles.Home}>
            <div className={styles.heroText}>
                <TopBar 
                    displayType={displayType}
                    setQuery={setQuery}
                    setDate={setDate}
                    loadMovies={loadMovies}
                    selectedTag={selectedTag}
                    setSelectedTag={setSelectedTag}
                    setDisplayType={setDisplayType}/>
                { displayType === "list" ?
                    <div className={`${selectedTag !== "home" && styles.mainBodySpacer}`}>
                        {selectedTag==="home" ?
                        
                        <Featured/>
                        :
                        <div className={styles.centeredGenres}>
                            <div className={styles.Home_quickSearch}>
                                <span>Search near: </span>
                                <EditableLabel 
                                    text={zip}
                                    className={styles.Home_quickSearch_zipLabel}
                                    labelClassName={styles.Home_quickSearch_zipLabel}
                                    inputClassName={styles.Home_quickSearch_zipInput}
                                    inputWidth='64px'
                                    inputHeight='25px'
                                    inputMaxLength={5}
                                    labelFontWeight='bold'
                                    inputFontWeight='bold'
                                    onFocus={handleFocus}
                                    onFocusOut={handleFocusOut}
                                />
                                <span> on </span>
                                <DatePicker
                                    className={styles.Home_quickSearch_calendar}
                                    onChange={e => changeDate(e)}
                                    value={date}
                                    minDate={new Date()}
                                />
                            </div>
                            {cards.length > 0 ?
                                <div className={styles.genreList}>
                                    {cards}
                                </div>
                            :    
                                <h3>{`No current films in this genre.`}</h3>
                            }
                        </div>
                        }
                        {selectedTag==="home" && 
                            <div className={styles.Home_quickSearch}>
                                <span>Search near: </span>
                                    <EditableLabel 
                                        text={zip}
                                        className={styles.Home_quickSearch_zipLabel}
                                        labelClassName={styles.Home_quickSearch_zipLabel}
                                        inputClassName={styles.Home_quickSearch_zipInput}
                                        inputWidth='64px'
                                        inputHeight='25px'
                                        inputMaxLength={5}
                                        labelFontWeight='bold'
                                        inputFontWeight='bold'
                                        onFocus={handleFocus}
                                        onFocusOut={handleFocusOut}
                                    />
                                    <span> on </span>
                                    <DatePicker
                                        className={styles.Home_quickSearch_calendar}
                                        onChange={e => changeDate(e)}
                                        value={date}
                                        minDate={new Date()}
                                    />
                            </div>
                        }
                        {cards && selectedTag === "home" ? 
                            <div>
                                <h1 className={styles.Home_sectionTitle}>New Arrivals</h1>
                                <div className={styles.HorizontalScroll}>
                                    {cards.slice(0, cards.length/3)}
                                </div>
                                <h1 className={styles.Home_sectionTitle}>For Kids</h1>
                                <div className={styles.HorizontalScroll}>
                                    {cards.slice(cards.length/3, 2*cards.length/3)}
                                </div>
                                <h1 className={styles.Home_sectionTitle}>Something Different</h1>
                                <div className={styles.HorizontalScroll}>
                                    {cards.slice(2*cards.length/3, cards.length)}
                                </div>
                            </div>
                        :
                        <div>{selectedTag === "home" && <div>Loading..</div>}</div>
                        }
                    </div>
                :
                    <div className={styles.Home_resultCardSection}>
                        {cards ? 
                            <div>
                                <h1 className={styles.Home_sectionTitle}>Search Results:</h1>
                                <div className={styles.Home_resultCards}>{cards}</div>
                            </div>
                        :
                        <div>{selectedTag === "home" && <div>Loading..</div>}</div>
                        }
                    </div>
                
                }
            </div>
        </div>
    )
}

export default Home;