import React, {useState} from 'react';
import styles from './featured.module.scss';
import PropTypes from 'prop-types';
import AutoplaySlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import { Link } from "react-router-dom";
import TextTransition, { presets } from "react-text-transition";

const Featured = (props) => {
    const [index, setIndex] = useState(0);

    const titles = {
        0: "The Invisible Man",
        1: "The Roads Not Taken",
        2: "1917",
    }

    const descriptions = {
        0: "After staging his own suicide, a crazed scientist uses his power to become invisible to stalk and terrorize his ex-girlfriend.",
        1: "Sally Potter’s new film stars Javier Bardem as a man tormented by visions of his other potential lives.",
        2: "World War I is perhaps unequaled in its horrific brutality. 1917 takes us into that horror and doesn’t let us out of it for two hours."
    }

    const links = {
        0: "/movies/570670/03-16-2020/07751",
        1: "/movies/567714/03-16-2020/07751",
        2: "/movies/530915/03-16-2020/07751"
    }
    
    const changeTitle = (e) => {
        setIndex(e.currentIndex);
    }

    return (
    <div className={styles.Featured}>
        <AutoplaySlider
            play={true}
            cancelOnInteraction={true}
            interval={6000}
            className={styles.Slider} 
            onTransitionEnd={e => changeTitle(e)}
            bullets={false} 
            organicArrows={true} 
        >
            <div data-src="https://image.tmdb.org/t/p/w1280/4U7hpTK0XTQBKT5X60bKmJd05ha.jpg" />
            <div data-src="https://image.tmdb.org/t/p/w1280/7GtX3NDh8S8ImqtTp4Ww02SXh4B.jpg" />
            <div data-src="https://image.tmdb.org/t/p/w1280/AuGiPiGMYMkSosOJ3BQjDEAiwtO.jpg" />
        </AutoplaySlider>
        <Link className={styles.Featured_link} to={`${links[index]}`}>
            <h1 className={styles.Featured_title}>
                <TextTransition
                    text={`Spotlight: ${titles[index]}`}
                    direction="down"
                    springConfig={presets.stiff}
                />
            </h1>
            <div className={styles.Featured_description}>
                <TextTransition
                    text={descriptions[index]}
                    direction="down"
                    springConfig={presets.stiff}
                />
            </div>
        </Link>
    </div>
    )
}

export default Featured;

Featured.propTypes = {
    title: PropTypes.string
}