import React, {useState, useEffect} from 'react';
import styles from './movie.module.scss';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { IoIosArrowBack } from 'react-icons/io';
import DatePicker from 'react-date-picker';
import EditableLabel from 'react-inline-editing';
import {
    movies_index
} from '../../config/algolia';

const Movie = (props) => {

    const [movie, setMovie] = useState()
    const [zip, setZip] = useState(props.match.params.zip);
    const [searchDate, setSearchDate] = useState(parseDate(props.match.params.searchDate));
    const [timeCards, setTimeCards] = useState();
    const times = ["4:30PM", "6:30PM", "7:45PM", "8:45PM", "9:30PM", "10:30PM"]
    const baseImageURL = "https://image.tmdb.org/t/p/w1280";

    useEffect(() => {
        getMovie();
        if (window.scrollY !== 0) {
            window.scrollTo(0,0);
        }
        setTimeCards(createTimeCards());
    }, [])
    
    const createTimeCards = () => {
        let timeDivs = [];
        times.map((time, index) => {
            return timeDivs.push(
                <h6 className={styles.MoviePage_timeSlot} key={index}>{time}</h6>
            )
        })
        console.log(timeDivs)
        return timeDivs;
    }

    const getMovie = () => {
        let query = props.match.params.id;
        movies_index.getObject(`${query}`)
        .then((data) => {
            setMovie(data);
		})
    }

    function parseDate(s) {
        var p = s.split('-');
        return new Date(p[2], p[0]-1, p[1]);
      }

    const handleFocus = (text) => {
    }

    const handleFocusOut = (text) => {
        if (text === "") {
            setZip("90210");
        } else {
            setZip(text);
        }
    }

    return (
        <div className={styles.MoviePage}>
            {movie ? 
                <div>
                    <Link to={``}>
                        <IoIosArrowBack size="3em" className={styles.MoviePage_backButton}/>
                    </Link>
                    <img 
                        className={styles.MoviePage_poster } 
                        src={baseImageURL+movie.poster_path} 
                        alt={movie.original_title}
                    />
                    <div className={styles.MoviePage_textBlock}>
                        <h1 className={styles.MoviePage_title}>{movie.original_title}</h1>
                        <h5 className={styles.MoviePage_rating}>{`Rating: ${movie.vote_average} / 10 | ${movie.mpaa}`}</h5>
                        <div className={styles.MoviePage_description}>{movie.overview}</div>
                        <h1 className={styles.MoviePage_timesTitle}>Movie Times</h1>
                        <div className={styles.MoviePage_quickSearch}>
                        <span>Search near: &nbsp;</span>
                            <EditableLabel 
                                text={zip}
                                className={styles.MoviePage_quickSearch_zipLabel}
                                labelClassName={styles.MoviePage_quickSearch_zipLabel}
                                inputClassName={styles.MoviePage_quickSearch_zipInput}
                                inputWidth='64px'
                                inputHeight='25px'
                                inputMaxLength={5}
                                labelFontWeight='bold'
                                inputFontWeight='bold'
                                onFocus={handleFocus}
                                onFocusOut={handleFocusOut}
                            />
                            <span>on &nbsp; </span>
                            <DatePicker
                                className={styles.MoviePage_quickSearch_calendar}
                                onChange={e => setSearchDate(e)}
                                value={searchDate}
                                minDate={new Date()}
                            />
                        </div>
                        <div className={styles.MoviePage_timeSlots}>
                            <div className={styles.theater_block}>
                                <div className={styles.times_theater}>AMC Freehold Metroplex:</div>
                                <div className={styles.times_theaterDistance}>2 mi</div>
                                <div className={styles.times_allTimes}>{timeCards.slice(0, timeCards.length/2)}</div>
                            </div>

                            <div className={styles.theater_block}>
                                <div className={styles.times_theater}>New Brunswick Cinemas:</div>
                                <div className={styles.times_theaterDistance}>5 mi</div>
                                <div className={styles.times_allTimes}>{timeCards.slice(timeCards.length/2, timeCards.length)}</div>
                            </div>

                            <div className={styles.theater_block}>
                                <div className={styles.times_theater}>AMC Monmouth Mall:</div>
                                <div className={styles.times_theaterDistance}>10 mi</div>
                                <div className={styles.times_allTimes}>{timeCards}</div>
                            </div>
                        </div>
                    </div>
                </div>
            :
                <div className={styles.MoviePage_loading}>Loading</div>
            }

    </div>
    )
}

export default Movie;

Movie.propTypes = {
    title: PropTypes.string
}