import * as algoliasearch from 'algoliasearch';

//reference to the specific Algolia "movies" index
const ALGOLIA_MOVIES = "movies";

//constants to access our Algolia index
const ALGOLIA_ID = 'NQ4672YNUE';
const ALGOLIA_SEARCH_KEY = 'e9ab595cfc0bf2b24282ef5159a5dc34';

export const client = algoliasearch(ALGOLIA_ID, ALGOLIA_SEARCH_KEY, { protocol: 'https:' });

export const movies_index = client.initIndex(ALGOLIA_MOVIES);