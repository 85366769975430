import React, {useState, useEffect} from 'react';
import styles from './topBar.module.scss';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FiSearch } from 'react-icons/fi';
import { IoIosArrowBack } from 'react-icons/io';

const TopBar = (props) => {

    const [visibleNav, setVisibleNav] = useState(false);
    const [selectedDate, setSelectedDate] = useState("today");

    const searchBar = React.createRef();
        
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    }, [])

    useEffect(() =>{
        if (props.displayType === "list") {
            window.scrollTo(0,0)
            document.getElementById("home").classList.add(styles.active)
        } else {
            window.scrollTo(0,0)
            document.getElementById("today").classList.add(styles.active)
        }
    }, [props])

    useEffect(() => {
        if (searchBar.current !== null) {
            searchBar.current.focus()
        }
    }, [searchBar])

    const setDate = (e, date) => {
        console.log(e)
        console.log(date)
        document.getElementById(selectedDate).classList.toggle(styles.active);
        document.getElementById(e).classList.toggle(styles.active);
        setSelectedDate(e);
    }


    // If the window is scrolled more than 225 vertical pixels from the top (tested and proven value), the burger menu button 
    // color will become TED-red (#e62b1e). If it is less  than 225 pixels from the top, the burger menu button will be white. 
    const handleScroll = () => {
        let scrollTop = window.scrollY;
        if (scrollTop > 10 && !visibleNav !== "#e62b1e") {
          setVisibleNav(true)
        }
        else if (scrollTop < 10 && visibleNav !== "#fff") {
            setVisibleNav(false)
        }
    }

    const selectTag = (e) => {
        document.getElementById(props.selectedTag).classList.remove(styles.active);
        document.getElementById(e).classList.add(styles.active);
        props.setSelectedTag(e);
    }

    const searchMode = (e) => {
        props.setDisplayType("search")
    }

    const queryChange = (e) => {
        console.log(e.target.value)
        props.loadMovies(e.target.value)
        // props.setQuery(e.target.value)

    }

    return (
    <div>
        { props.displayType === "list" ?
            <div className={classNames(styles.TopBar__displayTags, `${visibleNav ? styles.__visible : ""}`)}>
                <div className={styles.TopBar_categories}>
                    <div id="home" className={styles.TopBar_category} onClick={e => selectTag("home")}>Home</div>
                    <div id="all" className={styles.TopBar_category} onClick={e => selectTag("all")}>All</div>
                    <div id="action" className={styles.TopBar_category} onClick={e => selectTag("action")}>Action</div>
                    <div id="animated" className={styles.TopBar_category} onClick={e => selectTag("animated")}>Animated</div>
                    <div id="kids" className={styles.TopBar_category} onClick={e => selectTag("kids")}>Kids</div>
                    <div id="comedy" className={styles.TopBar_category} onClick={e => selectTag("comedy")}>Comedy</div>
                    <div id="drama" className={styles.TopBar_category} onClick={e => selectTag("drama")}>Drama</div>
                    <div id="thriller" className={styles.TopBar_category} onClick={e => selectTag("thriller")}>Thriller</div>
                    <div id="horror" className={styles.TopBar_category} onClick={e => selectTag("horror")}>Horror</div>
                    <div id="scifi" className={styles.TopBar_category} onClick={e => selectTag("scifi")}>SciFi</div>
                </div>
                <div className={styles.TopBar_search} id={"Search"}>
                    <FiSearch onClick={e => searchMode(e)} />
                </div>
            </div>
        :
            <div className={classNames(styles.TopBar__displaySearch, `${visibleNav ? styles.__fixedSearch : ""}`)}>
                <div className={styles.TopBar_search_searchRow}>
                    <IoIosArrowBack className={styles.back} onClick={e => props.setDisplayType("list")} size="2em"/>
                    <div className={styles.TopBar_search} id={"Search"}>
                        <input 
                            ref={searchBar}
                            value={props.query}
                            className={styles.TopBar_searchInput}
                            placeholder="Type a movie name..."
                            onChange={e => queryChange(e)}    
                        />
                        <FiSearch
                            size="1em" 
                            className={styles.TopBar_search_icon} 
                            onClick={e => props.setDisplayType("search")} 
                        />
                    </div>
                </div>
                <div className={styles.TopBar_search_tagRow}>
                    <div className={styles.TopBar_datePick}>Set Date</div>
                    <div className={styles.TopBar_datePick} onClick={e => setDate("today", new Date())} id="today">Today</div>
                    <div className={styles.TopBar_datePick} onClick={e => setDate("tomorrow", new Date().setDate(new Date() + 1))} id="tomorrow">Tomorrow</div>
                </div>

            </div>
        }
    </div>

    )
}

export default TopBar;

TopBar.propTypes = {
    title: PropTypes.string
}